<template>
    <header class="header_wrap">
        <div>
            <button class="header_back_btn">Back</button>
            <p class="header_title">
                {{ $t(title) }}
            </p>
        </div>
        <div class="headerInfo">  
            <div class="grobal"></div>
            <select v-model="$i18n.locale">
                <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
                    {{ locale }}
                </option>
            </select>
            <div class="user_info">
                <img :src="usericon" alt="profile"
                    class="user_profile_img" />
                <div class="user_data">
                    <p class="name">{{ userId }}</p>
                    <p class="date">{{ new Date().toLocaleString() }}</p>
                </div>
            </div>
            <button class="logout_btn" v-on:click="clickLogout">{{ $t("btn.logout") }}</button>
        </div>
    </header>
</template>

<script>
export default {
    props: ["title"],
    computed: {
        userId() {
            return this.$store.getters['userStore/userId'];
        },
        userName() {
            return this.$store.getters['userStore/userName'];
        }
    },
    methods: {
        async clickLogout() {
            await this.$store.dispatch('userStore/logout')
            .then(() => this.$router.push({name: 'Login'}))
        }
    },
    data() {
        return {
            usericon: require("@/assets/images/gnb/com-hd-icon-user.png")
        }
    }
}
</script>
<style scoped>
select {
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  width: 140px;
  height: 34px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  appearance: auto;
}
</style>

<style scoped>
@import "@/assets/css/header.css"
</style>