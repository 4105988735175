//import Vue from 'vue'
import { createI18n } from 'vue-i18n'
import en from './en.json'
import ko from './ko.json'

/*
 * 안내문구 msg
 * 버튼 btn
 * 테이블 컬럼명 col
 * 일반 txt
 */

export const i18n = createI18n({
    // legacy: false, // you must set `false`, to use Composition API
    locale: 'English',
    fallbackLocale: '한국어',
    messages: {'English': en, '한국어': ko},
})